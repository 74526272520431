import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {lastValueFrom, of, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, switchMap, takeUntil} from 'rxjs/operators';
import {CdkConfigService} from '@cdk/services/config.service';
import {Pagination, Usuario} from '../../models';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
    CdkChaveAcessoPluginComponent
} from '../chave-acesso/cdk-chave-acesso-plugins/cdk-chave-acesso-plugin.component';
import {LoginService} from '../../../app/main/auth/login/login.service';
import {MatDialog} from '../../angular/material';
import {DynamicService} from 'modules/dynamic.service';
import {modulesConfig} from 'modules/modules-config';
import {SearchBarService} from '../search-bar/search-bar.service';
import {Router} from '@angular/router';

@Component({
    selector: 'cdk-barra-pesquisa',
    templateUrl: './barra-pesquisa.component.html',
    styleUrls: ['./barra-pesquisa.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarraPesquisaComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('dynamicComponent', {
        static: false,
        read: ViewContainerRef
    }) buscaPersonalizadaContainer: ViewContainerRef;

    @ViewChild('buscaComponent', {
        static: false,
        read: ViewContainerRef
    }) buscaComponent: ViewContainerRef;

    @Input()
    processoPagination: Pagination;

    @Output()
    inputText: EventEmitter<any>;

    collapsed: boolean;
    cdkConfig: any;

    form: FormGroup;

    activeCard = 'form';

    searchField = 'NUP';

    searchFieldName = 'NUP';

    module = 'administrativo';

    // Private
    private _unsubscribeAll: Subject<any>;

    searchQuery = '';

    usuario: Usuario;

    isUserConsultivo: boolean;

    /**
     * Constructor
     *
     * @param _cdkConfigService
     * @param _formBuilder
     * @param _loginService
     * @param _dialog
     * @param _searchBarService
     * @param _dynamicService
     * @param _changeDetectorRef
     */
    constructor(
        private _cdkConfigService: CdkConfigService,
        private _formBuilder: FormBuilder,
        private _loginService: LoginService,
        private _dialog: MatDialog,
        private _searchBarService: SearchBarService,
        private _dynamicService: DynamicService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router
    ) {
        this.form = this._formBuilder.group({
            processo: [null],
            tarefaNumero: [null],
            content: [null]
        });

        this.processoPagination = new Pagination();
        this.processoPagination.populate = ['especieProcesso', 'especieProcesso.generoProcesso', 'setorAtual', 'setorAtual.unidade'];
        this.processoPagination.context = {'modulo': this.module};

        // Set the defaults
        this.inputText = new EventEmitter();
        this.collapsed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.usuario = this._loginService.getUserProfile();
        const lotacaoPrincipal = this.usuario?.colaborador?.lotacoes.find(l => l.principal); // ALTERADO PELA PGE-RS: colaborador pode ser opcional.
        this.isUserConsultivo = lotacaoPrincipal?.setor?.especieSetor?.generoSetor?.nome === 'CONSULTIVO';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void> {

        // Subscribe to config changes
        this._cdkConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.cdkConfig = config;
                }
            );

        // Subscribe to searchBar field changes
        this._searchBarService.searchField.pipe(
            takeUntil(this._unsubscribeAll),
            filter(campo => !!campo)
        ).subscribe(
            (campo) => {
                this.searchField = campo;
                this.form.get('processo').reset();
                this._changeDetectorRef.detectChanges();
            }
        );

        // Subscribe to searchBar field changes
        this._searchBarService.searchFieldName.pipe(
            takeUntil(this._unsubscribeAll),
            filter(campo => !!campo)
        ).subscribe(
            (campo) => {
                this.searchFieldName = campo;
                this._changeDetectorRef.detectChanges();
            }
        );

        // Subscribe to searchBar module changes
        this._searchBarService.module.pipe(
            takeUntil(this._unsubscribeAll),
            filter(module => !!module)
        ).subscribe(
            (module) => {
                this.module = module;
                this.processoPagination.context = {'modulo': this.module};
                this._changeDetectorRef.detectChanges();
            }
        );

        if (this.form.get('processo')) {
            this.form.get('processo').valueChanges.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((value) => {
                        if (value && typeof value === 'object') {
                            if (value.visibilidadeExterna || this._loginService.isGranted('ROLE_COLABORADOR')) {
                                this.inputText.emit({id: value.id});
                                return of([]);
                            }

                            const dialogRef = this._dialog.open(CdkChaveAcessoPluginComponent, {
                                width: '600px'
                            });

                            dialogRef.afterClosed().pipe(filter(result => !!result)).subscribe((result) => {
                                this.inputText.emit({id: value.id, chaveAcesso: result});
                                return of([]);
                            });
                        }

                        return of([]);
                    }
                )
            ).subscribe();
        }

        if(this._searchBarService.getPreferences()) {
            const preferences = this._searchBarService.getPreferences();

            this._searchBarService.setSearchField(preferences.sidebarSearchFieldPreference);
            this._searchBarService.setSearchFieldName(preferences.sidebarSearchFieldNamePreference);

            // ALTERADO PELA PGE-RS: adicionado. 
            const input = document.querySelector('.input-field') as HTMLInputElement;
            let v = '';
            if (preferences.sidebarSearchFieldPreference === 'outroNumero') {
                v = '%';
            }
            if (input) {    
                setTimeout(() => {
                    this.setFloatingLabel(true);
                    input.focus();
                    input.value = v;
                }, 150);  
            }
            
        } else {
            this._searchBarService.setSearchField('NUP');
            this._searchBarService.setSearchFieldName('NUP');
        }

        this._searchBarService.setModule('administrativo');

    }

    ngAfterViewInit(): void {
        const path = '@cdk/components/search-bar';
        const pathPesquisa = '@cdk/components/search-bar-busca';

        modulesConfig.forEach((module) => {
            if (module.components.hasOwnProperty(pathPesquisa)) {
                module.components[pathPesquisa].forEach(((c) => {
                    this._dynamicService.loadComponent(c)
                        .then((componentFactory) => {
                            // ALTERADO PELA PGE-RS: adicionado 'if (this.buscaComponent '
                            if (this.buscaComponent && this.isUserConsultivo){
                                this.buscaComponent.createComponent(componentFactory);
                            }
                        });
                }));
            }
        });

        modulesConfig.forEach((module) => {
            if (module.components.hasOwnProperty(path)) {
                module.components[path].forEach(((c) => {
                    this._dynamicService.loadComponent(c)
                        .then((componentFactory) => {
                            this.buscaPersonalizadaContainer.createComponent(componentFactory);
                        });
                }));
            }
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void {
        this.collapsed = true;
    }

    /**
     * Expand
     */
    expand(): void {
        this.collapsed = false;
    }

    checkProcesso(): void {
        const processo = this.form.get('processo').value;
        if (!processo || typeof processo !== 'object') {
            this.form.get('processo').setValue(null);
        }
    }

    selecionaCampo(campo: string, nome: string, module: string): void {
        this._searchBarService.setSearchField(campo);
        this._searchBarService.setSearchFieldName(nome);
        this._searchBarService.setModule(module);

        // ALTERADO PELA PGE-RS: adicionado. 
        let v = '';
        if (campo === 'outroNumero') {
            v = '%';
        }
        const input = document.querySelector('.input-field') as HTMLInputElement;
        if (input) {    
            setTimeout(() => {
                this.setFloatingLabel(true);
                input.focus();
                input.value = v;
            }, 150);  
        }        
    }

    routeSearch(): void {
        this._router.navigate(['/apps/pesquisa/processos']).then();
    }

    onFocus() {
        this.setFloatingLabel(true);
    }

    onBlur() {
        const input = document.querySelector('.input-field') as HTMLInputElement;
        if (!this.form.get('processo')?.value && input?.value === '') {
            this.setFloatingLabel(false);
        }
    }

    onInput() {
        this.setFloatingLabel(true); // !!this.form.get('processo')?.value); // ALTERADO PELA PGE-RS: comentado
    }

    // ALTERADO PELA PGE-RS: adicionado
    limparCampo() {
        const input = document.querySelector('.input-field') as HTMLInputElement;
        if (input) {
            setTimeout(() => {
                this.setFloatingLabel(true);
                this.form.get('processo')?.setValue(null);
                input.focus();
                input.value = '';
            }, 150);            
        }
    }    

    /*  ALTERADO PELA PGE-RS: código abaixo comentado, será ativado em https://gitlab01.pgers.intra.rs.gov.br/supp/supp-administrativo-frontend/-/issues/245
    onEnter($event: KeyboardEvent) {
        $event.preventDefault();
        this._router.navigate(['/nova-rota'], { queryParams: { tarefa: this.form.get('tarefaNumero')?.value } });
    }
    */

    private setFloatingLabel(isFloating: boolean) {
        const label = document.querySelector('.input-label');
        if (label) {
            label.classList.toggle('floating', isFloating);
        }
    }
}
