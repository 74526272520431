<form name="form" [formGroup]="form">

    <div class="cdk-barra-pesquisa">
        <div class="sm:hidden flex items-center">
            <button (click)="routeSearch()" mat-icon-button class="custom-search-button"
                    aria-label="Pesquisar por">
                <mat-icon svgIcon="mat_outline:manage_search"></mat-icon>
            </button>
        </div>

        <div class="barra-pesquisa-final">

            <ng-container *ngIf="isUserConsultivo">
                <ng-template #buscaComponent></ng-template>

            </ng-container>

            <!-- /* ALTERADO PELA PGE-RS: ajustes visuais. */ -->
            <div class="cdk-barra-pesquisa-content xs:hidden">
                <div class="form-field-flex">
                    <button mat-icon-button
                            *ngIf="!form.get('processo')?.value"
                            class="custom-search-button"
                            aria-label="Pesquisar por"
                            [matTooltip]="'Pesquisar por ' + this.searchFieldName"
                            [matMenuTriggerFor]="menuBusca">
                        <mat-icon svgIcon="mat_outline:manage_search"></mat-icon>
                    </button>
                    <button mat-icon-button
                        *ngIf="form.get('processo')?.value"
                        class="custom-search-button"
                        matTooltip="Limpar" 
                        (click)="limparCampo()">
                        <mat-icon>close</mat-icon> 
                    </button>
                    <!-- /* ALTERADO PELA PGE-RS: remoção de 'rápida de processos ' */ -->
                    <div class="input-container">
                        <label [ngClass]="{'floating': form.get('processo')?.value}"
                               class="input-label"
                               for="processoInput">
                               Pesquisar por {{ searchFieldName }}
                        </label>
                        <!-- /* ALTERADO PELA PGE-RS: adicionado spellcheck="false" ' */ -->
                        <input id="processoInput" spellcheck="false"
                               class="input-field flex grow"
                               formControlName="processo"
                               (focus)="onFocus()"
                               (blur)="onBlur()"
                               (input)="onInput()"
                               matInput
                               [matAutocomplete]="processoSearch.autocomplete">
                        <!-- ALTERADO PELA PGE-RS: código abaixo comentado, será ativado em https://gitlab01.pgers.intra.rs.gov.br/supp/supp-administrativo-frontend/-/issues/245
                        <input id="tarefaInput" spellcheck="false" *ngIf="searchField === 'Tarefa'"
                                class="input-field flex grow"     
                                formControlName="tarefaNumero"                           
                                (focus)="onFocus()"
                                (blur)="onBlur()"
                                (input)="onInput()"
                                (keyup.enter)="onEnter($event)"
                                matInput>
                        -->
                    </div>
                    <cdk-processo-search-autocomplete
                        #processoSearch="processoSearchAutocomplete"
                        [searchField]="searchField"
                        [openBlank]="true"
                        [control]="form.get('processo')"
                        [pagination]="processoPagination">
                    </cdk-processo-search-autocomplete>
                </div>
            </div>
        </div>

    </div>
    <mat-menu #menuBusca="matMenu" class="menu-busca" [overlapTrigger]="false" xPosition="before">
        <!-- /* ALTERADO PELA PGE-RS: ajustes visuais. */ -->
        <div class="m-1">
            <div class="custom-search-header" style="border-bottom: 1px solid lightgray; margin-bottom: 8px;">
                <span style="padding-bottom: 6px;">Pesquisar por:</span>
            </div>
            <!-- ALTERADO PELA PGE-RS: código abaixo comentado, será ativado em https://gitlab01.pgers.intra.rs.gov.br/supp/supp-administrativo-frontend/-/issues/245                        
            <div class="campo" (click)="selecionaCampo('Tarefa', 'Tarefa', 'administrativo')"
                 [ngClass]="{'selected': searchField === 'Tarefa'}">
                <span>Tarefa</span>
                <mat-icon class="text-base text-primary w-[24px] h-[24px] min-w-[14px] min-h-[14px]"
                          style="font-size: 24px !important;"
                          *ngIf="searchField === 'Tarefa'">check
                </mat-icon>
            </div>
            -->
            <div class="campo" (click)="selecionaCampo('NUP', 'NUP', 'administrativo')"
                 [ngClass]="{'selected': searchField === 'NUP'}">
                <span>NUP</span>
                <mat-icon class="text-base text-primary w-[24px] h-[24px] min-w-[14px] min-h-[14px]"
                          style="font-size: 24px !important;"
                          *ngIf="searchField === 'NUP'">check
                </mat-icon>
            </div>
            <div class="campo" (click)="selecionaCampo('outroNumero', 'Outro Número', 'administrativo')"
                 [ngClass]="{'selected': searchField === 'outroNumero'}">
                <span>Outro Número</span>
                <mat-icon class="text-base text-primary w-[24px] h-[24px] min-w-[14px] min-h-[14px]"
                          style="font-size: 24px !important;"
                          *ngIf="searchField === 'outroNumero'">check
                </mat-icon>
            </div>

            <ng-template #dynamicComponent></ng-template>
        </div>
    </mat-menu>
</form>
