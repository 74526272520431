import {Observable, of} from 'rxjs';
import {Processo} from '../../@cdk/models';
import {filter, switchMap} from 'rxjs/operators';


export const modulesConfigProa =
    {
        name: 'integracao-proa',
        generoProcesso: ['ADMINISTRATIVO', 'CONSULTIVO','CORREICIONAL'],
        routes: {
            'app/main/apps/processo/processo-edit': [
                {
                    path: 'dados-basicos-steps-proa',
                    loadChildren: () => import('modules/integracao-proa/app/main/apps/processo/processo-edit/dados-basicos/dados-basicos-pgers.module').then(m => m.DadosBasicosPgersModule)
                },
                {
                    path: 'remeter-proa',
                    loadChildren: () => import(
                        '../integracao-proa/app/main/apps/devolver-proa/devolver-proa.module'
                        ).then(m => m.DevolverProaModule)
                },
                {
                    path: 'exportar-proa',
                    loadChildren: () => import(
                        '../integracao-proa/app/main/apps/exportar-proa/exportar-proa.module'
                        ).then(m => m.ExportarProaModule)
                },
                {
                    path: 'integrar-proa',
                    loadChildren: () => import(
                        '../integracao-proa/app/main/apps/integrar-proa/integrar-proa.module'
                        ).then(m => m.IntegrarProaModule)
                }
            ],
            'app/main/apps/processo': [
                {
                    path: 'atividade-proa',
                    loadChildren: () => import(
                        '../integracao-proa/app/main/apps/atividade-proa-list/atividade-proa-list.module'
                        ).then(m => m.AtividadeProaListModule)

                },
                {
                    path: 'pasta-digital-proa',
                    loadChildren: () => import(
                        '../integracao-proa/@cdk/components/pasta-digital/cdk-pasta-digital-proa-view/cdk-pasta-digital-proa-view.module'
                        ).then(m => m.PastaDigitalProaViewModule)

                },
                {
                    path: 'sid',
                    loadChildren: () => import('modules/integracao-proa/app/main/apps/processo/sid/sid.module').then(m => m.SidModule)
                }
            ],
            'app/main/apps/tarefas/tarefa-detail': [
                {
                   path: 'nup/:processoHandle/pasta-digital-proa',
                     loadChildren: () => import(
                        '../integracao-proa/@cdk/components/pasta-digital/cdk-pasta-digital-proa-view/cdk-pasta-digital-proa-view.module'
                    ).then(m => m.PastaDigitalProaViewModule)

                }
            ]

        },
        mainMenu: [
            {
                id: 'protocolo',
                entries: [{
                    id: 'integracao-proa',
                    title: 'PROA',
                    type: 'item',
                    icon: 'dvr',
                    url: '/apps/processo/criar/editar/dados-basicos-steps-proa',
                    role: 'ROLE_COLABORADOR'
                }]
            }

        ],

        sidebars: {


            'app/main/apps/processo/sidebars/main': [
                {
                    nome: 'Downloads SID',
                    icon: 'cloud_queue',
                    link: 'sid',
                    processo: true,
                    role: 'ROLE_DOWNLOAD_SID_PROCESSO'
                },
                {
                    nome: 'Atividades PROA',
                    icon: 'list',
                    link: 'atividade-proa',
                    processo: true,
                    role: 'ROLE_COLABORADOR',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => {
                        return processo$.pipe(
                            filter((processo) => !!processo),
                            switchMap((processo) => {
                                if (processo.origemDados?.fonteDados === 'PROA') {
                                //if (processo.outroNumero?.indexOf('PROA')>-1) {
                                    return of(true);
                                }
                                return of(false);
                            })
                        );
                    }
                },                
                {
                    nome: 'Pasta Digital PROA',
                    icon: 'folder_open',
                    link: 'pasta-digital-proa',
                    processo: true,
                    role: ['ROLE_USUARIO_EXTERNO'],
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => {
                        return processo$.pipe(
                            filter((processo) => !!processo),
                            switchMap((processo) => {
                                if (processo.origemDados?.fonteDados === 'PROA') {
                                    //if (processo.outroNumero?.indexOf('PROA')>-1) {
                                    return of(true);
                                }
                                return of(false);
                            })
                        );
                    }
                },
               /* POR ORA, FICARÁ COMENTADO.
                {
                    nome: 'Remeter ao PROA',
                    icon: 'check_circle',
                    link: 'editar/remeter-proa',
                    processo: true,
                    role: 'ROLE_COLABORADOR',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => {
                        return processo$.pipe(
                            filter((processo) => !!processo),
                            switchMap((processo) => {
                                if (processo.origemDados?.fonteDados === 'PROA') {
                                    return of(true);
                                }
                                return of(false);
                            })
                        );
                    }
                }
               */
            ],

            'app/main/apps/processo/processo-edit/sidebars/main': [
                {
                    index: 0,
                    nome: 'Pasta Digital PROA',
                    icon: 'folder_open',
                    link: 'pasta-digital-proa',
                    processo: true,
                    role: ['ROLE_COLABORADOR','ROLE_USUARIO_EXTERNO'],
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => {
                        return processo$.pipe(
                            filter((processo) => !!processo),
                            switchMap((processo) => {
                                if (processo.origemDados?.fonteDados === 'PROA') {
                                    //if (processo.outroNumero?.indexOf('PROA')>-1) {
                                    return of(true);
                                }
                                return of(false);
                            })
                        );
                    }
                },
                {
                    index: 0,
                    nome: 'Remeter ao PROA',
                    link: 'editar/remeter-proa',
                    processo: true,
                    role: 'ROLE_COLABORADOR',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => {
                        return processo$.pipe(
                            filter((processo) => !!processo),
                            switchMap((processo) => {
                                if (processo.origemDados?.fonteDados === 'PROA') {
                                //if (processo.outroNumero?.indexOf('PROA')>-1) {
                                    return of(true);
                                }
                                return of(false);
                            })
                        );
                    }
                }
                /*
                ,{
                    index: 0,
                    nome: 'Exportar para o PROA',
                    link: 'editar/exportar-proa',
                    //url:'/apps/exportar-proa',
                    processo: true,
                    role: 'ROLE_COLABORADOR',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => {
                        return processo$.pipe(
                            filter((processo) => !!processo),
                            switchMap((processo) => {
                                if (processo.origemDados?.fonteDados != 'PROA') {
                                    return of(true);
                                }
                                return of(false);
                            })
                        );
                    }
                }

                ,{
                    index: 0,
                    nome: 'Integrar com o PROA',
                    link: 'editar/integrar-proa',
                    processo: true,
                    role: 'ROLE_COLABORADOR',
                    canShow: (processo$: Observable<Processo>): Observable<boolean> => {
                        return processo$.pipe(
                            filter((processo) => !!processo),
                            switchMap((processo) => {
                                if (processo.origemDados?.fonteDados != 'PROA') {
                                    return of(true);
                                }
                                return of(false);
                            })
                        );
                    }
                }
                */
            ]
        },

        components: {

            //'app/main/apps/tarefas/tarefa-detail': [
            //    () => import('modules/integracao-proa/app/main/apps/pasta-digital/button1.module').then(m => m.Button1Module)
            //],

            // Botões de sincronizar e integrar com o PROA
            'app/main/apps/processo': [
                () => import('modules/integracao-proa/app/main/apps/processo/processo-proa.module').then(m => m.ProcessoProaModule)
            ],

            //'@cdk/components/tarefa/cdk-encaminhamento-form': [
            //    () => import ('modules/integracao-proa/app/main/apps/encaminhamento-proa/button-encaminhamento-proa.module').then(m => m.ButtonEncaminhamentoProaModule),
            //],


            '@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item#text': [
                   () => import ('modules/integracao-proa/app/main/apps/tarefas/numero-proa/numero-proa.module').then(m => m.NumeroProaModule),
            ],


        },

        routerLinks: {
        },

        ajuda: [
        ],

        widgets: [
        ],

        generoAffinity: {
            '@cdk/components/tarefa/cdk-tarefa-list/cdk-tarefa-list-item#text': {
                'ADMINISTRATIVO': [],
                'CONSULTIVO': [],
                'CORREICIONAL': [],
            },
        },
        navigationConverter: {
            mainMenu: {}
        }
    };
