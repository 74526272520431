<mat-toolbar class="p-0 mat-elevation-z1">

    <div class="toolbar-element flex w-full h-full flex-row justify-start items-center content-center">

        <div class="flex grow shrink-0 basis-auto flex-row justify-end items-center content-center">

            <button mat-icon-button class="navbar-toggle-button lg:hidden"
                    *ngIf="!hiddenNavbar && !rightNavbar" (click)="toggleSidebarOpen('navbar')">
                <mat-icon class="text-secondary">menu</mat-icon>
            </button>

            <div class="toolbar-separator lg:hidden" *ngIf="!hiddenNavbar && !rightNavbar"></div>

            <div class="flex flex-row justify-start items-center content-center" *ngIf="horizontalNavbar">
                <div class="logo ml-2">
                    <img class="logo-icon" [src]="_cdkConfigService.logo">
                </div>
            </div>
            <!-- ALTERADO PELA PGE-RS: removida a ROLE -->
            <cdk-barra-pesquisa class="mr-1 ml-1 pesquisa-rapida" (inputText)="search($event)"></cdk-barra-pesquisa>
        </div>

        <div class="flex flex-row grow-0 shrink basis-auto justify-start items-center content-center">
            <div class="avatar-element">
                <button mat-button [matMenuTriggerFor]="userMenu"
                        class="user-button">
                    <div class="flex flex-row justify-center items-center content-center shrink-1">
                        <img class="avatar mr-0 sm:mr-2 h-full w-40" src="assets/images/avatars/User.png"
                             *ngIf="!!userProfile?.imgPerfil?.conteudo === false">
                        <img class="avatar mr-0 sm:mr-2 h-full w-40" [src]="userProfile.imgPerfil.conteudo|safeResourceUrl"
                             *ngIf="!!userProfile?.imgPerfil?.conteudo === true">
                        <span class="username mr-3 xs:hidden">{{userProfile?.nome.split(' ')[0] + ' ' + userProfile?.nome.split(' ').slice(-1)[0][0] + '.'}}</span>
                        <mat-icon class="xs:hidden">keyboard_arrow_down</mat-icon>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                    <button mat-menu-item (click)="goConfiguracoes()">
                        <mat-icon>account_circle</mat-icon>
                        <span>Configurações</span>
                    </button>

                    <button mat-menu-item onclick="window.location.href = window.location.href.split('?')[0] + '?cacheBust=' + new Date().getTime();">
                        <mat-icon>loop</mat-icon>
                        <span>Recarregar</span>
                    </button>

                    <button mat-menu-item (click)="goLogout()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sair</span>
                    </button>

                </mat-menu>
            </div>

            <div class="toolbar-separator xs:hidden" *ngIf="!hiddenNavbar && rightNavbar"></div>

            <div class="toolbar-separator xs:hidden"></div>

            <button mat-icon-button
                    class="mr-1 ml-1 quick-panel-toggle-button"
                    aria-label="Assinadores"
                    aria-hidden="false"
                    [matMenuTriggerFor]="menuAssinadores">
                <mat-icon aria-hidden="false" matTooltip="Assinadores" [matBadge]="assinadores.length" matBadgePosition="above after"
                          [matBadgeColor]="assinadores.length ? 'primary' : 'warn'">
                    lock
                </mat-icon>
            </button>
            <mat-menu #menuAssinadores="matMenu" class="menuNotificacao" [overlapTrigger]="false" xPosition="before">
                <div class="notification-header" *ngIf="assinadores.length">
                    <div>
                        <span class="title">Assinadores</span>
                    </div>
                </div>
                <mat-list *ngIf="assinadores.length; else vazioAss">
                    <ng-container *ngFor="let assinador of assinadores; let i = index; let len = count">
                        <mat-list-item>
                            <div class="notification-item" (click)="$event.stopPropagation(); setAssinadorSelecionado(assinador)">
                                <p style="cursor: pointer; margin: 15px 0 15px 10px; text-align: initial;"
                                   [innerHTML]="assinador.hostName">
                                </p>
                                <div *ngIf="assinador.selecionado" class="actions">
                                    <mat-icon class="mat-18" title="Selecionado" (click)="$event.stopPropagation()">done</mat-icon>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-divider *ngIf="i !== len-1"></mat-divider>
                    </ng-container>
                </mat-list>
                <ng-template #vazioAss>
                    <p class="textoMenu">Não há assinadores</p>
                </ng-template>
            </mat-menu>

            <div class="toolbar-separator xs:hidden"></div>

            <button mat-icon-button
                    class="mr-1 ml-1 quick-panel-toggle-button"
                    aria-label="Notificações"
                    aria-hidden="false"
                    [matMenuTriggerFor]="menuNoticacoes">
                <mat-icon *ngIf="carregandoNotificacao">
                    <mat-spinner color="primary" diameter="20"></mat-spinner>
                </mat-icon>
                <mat-icon aria-hidden="false" matTooltip="Notificações" *ngIf="!carregandoNotificacao" [matBadge]="notificacoesCount" matBadgePosition="above after"
                          [matBadgeColor]="notificacoesCount ? 'warn' : 'primary'"
                          (click)="reload()">
                    notifications
                </mat-icon>
            </button>
            <mat-menu #menuNoticacoes="matMenu" class="menuNotificacao" [overlapTrigger]="false" xPosition="before">
                <div class="notification-header" *ngIf="notificacoes.length">
                    <div>
                        <span class="title">Notificações</span>
                        <span class="actions" *ngIf="checkedNotifications.length">
                            {{ this.checkedNotifications.length }}
                            <mat-icon class="mat-18" (click)="$event.stopPropagation()" [matMenuTriggerFor]="menuNoticacoesDetail">
                                more_vert
                            </mat-icon>
                            <mat-menu #menuNoticacoesDetail="matMenu" xPosition="before">
                                <mat-list>
                                    <mat-list-item>
                                         <button mat-menu-item (click)="marcarSelecionadosComoLido()">
                                            <span>Marcar como lido</span>
                                         </button>
                                    </mat-list-item>
                                    <mat-list-item>
                                         <button mat-menu-item (click)="removerSelecionados()">
                                            <span>Remover</span>
                                         </button>
                                    </mat-list-item>
                                </mat-list>
                            </mat-menu>
                        </span>
                    </div>
                    <div class="actions">
                        <mat-icon class="mat-18" title="Marcar como lido" (click)="marcarTodasComoLida()">done_all</mat-icon>
                        <mat-icon class="mat-18" title="Remover" (click)="excluirTodasNotificaoes()">delete</mat-icon>
                        <mat-icon class="mat-18" title="Histórico de notificações" (click)="goNotificacoes()">history</mat-icon>
                    </div>
                </div>
                <mat-list *ngIf="notificacoes.length; else vazio">
                    <ng-container *ngFor="let notificacao of notificacoes; let i = index; let len = count">
                        <mat-list-item [class.non-readed]="!notificacao.dataHoraLeitura">
                            <div class="notification-item" *ngIf="getNotificacaoContext(notificacao)?.id" (click)="$event.stopPropagation()">
                                <mat-checkbox color="primary" (change)="checkNotification($event.checked, notificacao)"></mat-checkbox>
                                <div class="data-hora">
                                    <div>{{notificacao.criadoEm?.toDate() | date: 'dd/MM/yy HH:mm':'-0300'}}</div>
                                </div>
                                <p [innerHTML]="notificacao.conteudo" (click)="sendToTarget(notificacao);"> <!-- ALTERADO PELA PGE-RS: removido | titleCasePipe-->
                                </p>
                                <div class="actions">
                                    <mat-icon class="mat-18" title="Abrir" (click)="sendToTarget(notificacao)">search</mat-icon>
                                    <mat-icon class="mat-18" title="Abrir em outra aba" (click)="$event.stopPropagation(); openNewTab(notificacao)">open_in_new</mat-icon>
                                    <mat-icon class="mat-18" title="Download" (click)="$event.stopPropagation(); download(notificacao)" *ngIf="getNotificacaoContext(notificacao)?.componente_digital_id">cloud_download</mat-icon>
                                    <mat-icon class="mat-18" title="Marcar como lida" (click)="$event.stopPropagation(); toggleLida(notificacao)" *ngIf="!notificacao.dataHoraLeitura">done</mat-icon>
                                    <mat-icon class="mat-18" title="Remover" (click)="$event.stopPropagation(); removerNotificacao(notificacao)">delete</mat-icon>
                                </div>
                            </div>
                            <div class="notification-item" *ngIf="!getNotificacaoContext(notificacao)?.id" (click)="$event.stopPropagation()">
                                <mat-checkbox color="primary" (change)="checkNotification($event.checked, notificacao)"></mat-checkbox>
                                <div class="data-hora">
                                    <div>{{notificacao.criadoEm?.toDate() | date: 'dd/MM/yy HH:mm':'-0300'}}</div>
                                </div>
                                <p [innerHTML]="notificacao.conteudo"> <!-- ALTERADO PELA PGE-RS: removido | titleCasePipe-->
                                </p>
                                <div class="actions">
                                    <mat-icon class="mat-18" title="Marcar como lida" (click)="$event.stopPropagation(); toggleLida(notificacao)" *ngIf="!notificacao.dataHoraLeitura">done</mat-icon>
                                    <mat-icon class="mat-18" title="Remover" (click)="$event.stopPropagation(); removerNotificacao(notificacao)">delete</mat-icon>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-divider *ngIf="i !== len-1 && !notificacao.dataHoraLeitura"></mat-divider>
                    </ng-container>
                </mat-list>
                <ng-template #vazio>
                    <div class="actions-sem-not" >
                        <p class="textoMenu">Não há notificações</p>
                        <mat-icon class="mat-18" title="Histórico de notificações" (click)="goNotificacoes()">history</mat-icon>
                    </div>
                </ng-template>
            </mat-menu>

            <div class="toolbar-separator xs:hidden" *ngIf="!hiddenNavbar && rightNavbar"></div>

            <div class="toolbar-separator xs:hidden"></div>

            <button mat-icon-button
                    id="help"
                    class="mr-1 ml-1 quick-panel-toggle-button2"
                    (click)="toggleSidebarOpen('ajudaPanel')"
                    aria-label="Ajuda">
                <mat-icon matTooltip="Ajuda">live_help</mat-icon>
            </button>

            <div class="toolbar-separator xs:hidden"></div>

            <!-- COMENTADO PELA PGE-RS, issue: https://gitlab01.pgers.intra.rs.gov.br/supp/supp-administrativo-frontend/-/issues/259 -->
            <!-- <button mat-icon-button
                    class="mr-1 ml-1 quick-panel-toggle-button2"
                    (click)="toggleChatPanel()"
                    aria-label="Mensagem">
                <mat-icon *ngIf="carregandoChat">
                    <mat-spinner color="primary" diameter="20"></mat-spinner>
                </mat-icon>
                <mat-icon matTooltip="Chat" *ngIf="!carregandoChat" aria-hidden="false"
                          [matBadge]="totalChatMensagensNaoLidas" matBadgePosition="above after"
                          [matBadgeColor]="totalChatMensagensNaoLidas ? 'warn' : 'primary'">
                    chat
                </mat-icon>
            </button> -->

            <div class="toolbar-separator xs:hidden" *ngIf="!hiddenNavbar && rightNavbar"></div>

            <div class="toolbar-separator xs:hidden"></div>

            <button mat-icon-button
                    class="mr-1 ml-1 quick-panel-toggle-button3"
                    *ngIf="(!this._cdkSidebarService.getSidebar('quickPanel').isLockedOpen || this._cdkSidebarService.getSidebar('quickPanel').folded)
                     && operacoesProcessando === 0"
                    (click)="toggleQuickPanel()"
                    aria-label="Operações">
                <mat-icon matTooltip="Últimas Operações">format_list_bulleted</mat-icon>
            </button>

            <div style="width: 43px !important; max-width: 43px !important; min-width: 43px !important;" class="quick-panel-toggle-button3 mat-icon-button flex justify-center items-center content-center"
                 matTooltip="Processando {{operacoesPendentes}}/{{operacoesProcessando}} item(s)..."
                 *ngIf="(!this._cdkSidebarService.getSidebar('quickPanel').isLockedOpen || this._cdkSidebarService.getSidebar('quickPanel').folded)
                  && operacoesProcessando > 0"
                 (click)="toggleQuickPanel()">
                <mat-spinner class="m-2" diameter="24"></mat-spinner>
            </div>
        </div>

    </div>
    <div *ngIf="titulo === 'Inicia Tour'">
        <shepherd></shepherd>
    </div>
</mat-toolbar>
